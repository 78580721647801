import React from 'react'
import s from 'styled-components'
import Img from 'gatsby-image'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { FUTURA_BOLD, FUTURA_REGULAR, COOPER_NOUVEAU, PEACHY_KEEN } from '../../../styles/font'
import { StyledAnchor } from '../../shared/Typography'


const Heading = s.div`
  width: fit-content;
  padding: 0.5rem;
  margin: 8rem auto 3rem;
  text-align: center;
  font-size: 4rem;
  line-height 4rem;
  border: 3px solid #019676;
  color: ${({ color = '#63CAAF' }) => color};
  ${PEACHY_KEEN}

  @media(max-width: 768px) {
    font-size: 3rem;
    line-height 3rem;
    margin-top: 4rem;
  }
`

const Container = s.div`
	border: 1px solid #DDDDDD;
    position: relative;
`

const Tag = s.div` 
	float: left;
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 1000;
	width: 100%;
	height: auto;
	background-color: rgba(0, 0, 0, 0.7);
	color: #FFFFFF;
	font-size: 1.2vw;
	${FUTURA_REGULAR}
	padding-left: 1rem;
	padding-top: 0.5rem;
	padding-right: 1rem;
	@media (max-width: 768px) {
		font-size: 1.2em;
	}
`
const Author = s.p`
	${FUTURA_BOLD}
	text-transform: uppercase;
	font-size: min(1.25vw, 0.9rem);
	@media (max-width: 768px) {
		font-size: 0.8rem;
    }
    margin-top: 0.75rem;
	margin-bottom: 0.25rem;
`

export const Badge = s.span`
  background-color: ${({ backgroundColor = '#283033' }) => backgroundColor};
  ${FUTURA_BOLD}
  border-radius: 10px;
  width: 3rem;
  color: black;
  font-size: 80%;
  margin-right: 1rem;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
`

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
}

const RestaurantCarousel = ({ articles, deviceType }) => (
  <>
    <div style={{ margin: '0 2rem' }}>
      <Heading color={'#019676'}>Restaurant Reviews</Heading>
    </div>
    <Carousel
      draggable={false}
      showDots={true}
      removeArrowOnDeviceType={['desktop']}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3500}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      deviceType={deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {articles &&
        articles.map(article => (
          <StyledAnchor link={article.published_link}>
            <Container>
              <Tag>
                {/* <Badge backgroundColor={article.color}>{article.award_tag}</Badge> */}
                <p style={{ marginTop: '0.2rem', marginBottom: '0.2rem', fontSize: '1.1rem' }}>{article.title}</p>
                <Author>BY {article.author}</Author>
              </Tag>
              <img src={article.image} style={{width: '100%', height: '450px', objectFit: 'cover', objectPosition: 'center'}}></img>
              {/* <Img fluid={article.img.childImageSharp.fluid} /> */}
              {/* style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', filter: 'brightness(60%)'}} */}
            </Container>
          </StyledAnchor>
        ))}
    </Carousel>
  </>
)

export default RestaurantCarousel
